import axios from "axios";
import { VisitOptions, Method } from "@inertiajs/core";
import { router } from "@inertiajs/react";
import { AttributeFilterParams, DateRangeFilterParams, EventGroup } from "@/types";
import { EnhancedStore } from "@reduxjs/toolkit";
import { setGlobalError } from "@/store/core";

// Wrap inertia router visit in promise to enable async/await syntax
export async function asyncVisit(url: string, method: Method, options?: VisitOptions) {
    return new Promise((resolve, reject) => {
        const wrappedOptions: VisitOptions = {
            ...options,
            method,
            onSuccess: (page) => resolve(page),
            onError: (errors) => reject(errors),
        };
        router.visit(url, wrappedOptions);
    });
}

// Initialize global error handling for axios requests
export function initAxios(store: EnhancedStore) {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            // Some errors don't have response (e.g. if a request was canceled it will raise CancelError)
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        // user is not logged in, navigate to login page
                        router.get(route('login'));
                        break;
                    case 419:
                        // Ask user to refresh the page
                        store.dispatch(setGlobalError({
                            message: 'The page has expired, please refresh and try again',
                            severity: 'warning',
                        }));
                        break;
                    default:
                        // Renders an error toast if needed
                        if (error.response.data.message) {
                            store.dispatch(setGlobalError({
                                message: error.response.data.message,
                                severity: 'error',
                            }));
                        }
                }
            }
            return Promise.reject(error);
        },
    );
}

// Raw AJAX API calls that don't use Inertia router
export async function fetchEventGroups() {
    return axios.get<EventGroup[]>(route('event-groups.raw-index'));
}

export async function createEventGroup(name: string) {
    return axios.post(route('event-groups.raw-store'), {
        name,
    });
}

export const fetchDistinctValues = async (field: string) => {
    return axios.get<string[]>(route('api.events.distinct-values', {field: field}));
}

export const fetchIebmsDistinctValues = async (field: string) => {
    return axios.get<string[]>(route('api.iebms-event.distinct-values', {field: field}));
}


export const fetchIebmsBookingsDistinctValues = async (field: string) => {
    return axios.get<string[]>(route('api.iebms-event.bookings.distinct-values', {field: field}));
}

export const fetchUserList = async () => {
    return axios.get(route('api.tag-list'));
}

export const fetchShDistinctValues = async (field: string) => {
    return axios.get<string[]>(route('api.sh-event.distinct-values', {field: field}));
}

export const fetchEventsFromApi = async (
    routeUrl: string,
    dateRangeFilter: DateRangeFilterParams,
    attributeFilter: AttributeFilterParams,
    page: number,
    size: number,
    groupId?: number,
    keywords?: string,
    sorting?: {
        sort_by: string,
        sort_dir: string,
    },
) => {
    const data = await axios.get(route(routeUrl, {...dateRangeFilter, ...attributeFilter, ...sorting, page: page, per_page: size, groupId: groupId, keywords }));
    // Only return events list
    return data.data.data;
}

export const fetchEventSubTypes = async () => {
    const params = new URLSearchParams();
    dependencyFieldValue.map(value => params.append(`${dependencyFieldId}[]`, value));
    return axios.get<string[]>(route('api.events.event-sub-types'), {params});
}
